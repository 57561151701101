import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex h-10 items-center" }
const _hoisted_2 = { class: "flex-grow" }
const _hoisted_3 = { class: "flex-grow overflow-hidden mb-3" }
const _hoisted_4 = { class: "text-pretty break-words whitespace-normal py-10" }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MoreInfo = _resolveComponent("MoreInfo")!
  const _component_InfoTable = _resolveComponent("InfoTable")!
  const _component_SmallToggleButton = _resolveComponent("SmallToggleButton")!

  return (_openBlock(), _createElementBlock("tr", {
    class: _normalizeClass(_ctx.color(_ctx.odd, _ctx.variant.work))
  }, [
    _createElementVNode("td", {
      class: _normalizeClass(["whitespace-nowrap border-r border-black text-left align-top bg-clip-padding lg:sticky left-0 z-10 w-96 max-w-sm", _ctx.color(_ctx.odd, _ctx.variant.work)])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.variant.work.display_name), 1),
        _createVNode(_component_MoreInfo, {
          class: "mx-1 border text-black",
          showInfo: !!_ctx.selectedWork,
          onClick: _ctx.toggleWork
        }, null, 8, ["showInfo", "onClick"])
      ]),
      (_ctx.selectedWork)
        ? (_openBlock(), _createBlock(_component_InfoTable, {
            key: 0,
            work: _ctx.selectedWork,
            class: "max-w-sm"
          }, null, 8, ["work"]))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("td", {
      class: _normalizeClass(["whitespace-nowrap align-top border-r border-black bg-clip-padding lg:sticky z-10 w-96 max-w-sm h-auto", _ctx.color(_ctx.odd, _ctx.variant.work)]),
      style: _normalizeStyle(_ctx.freezeWidth ? { left: _ctx.freezeWidth + 'px' } : {})
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variant.readers, (r) => {
        return (_openBlock(), _createElementBlock("div", {
          key: r.sigle,
          class: "max-w-sm text-left"
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["text-left flex items-center", _ctx.variant.work.canonical === 1 && +r.sigle % 100 > 0 ? 'pl-6' : ''])
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.toDisplayName(_ctx.variant.work, r)), 1)
            ]),
            _createVNode(_component_MoreInfo, {
              class: "mx-1 border text-black",
              showInfo: _ctx.selectedReader && _ctx.selectedReader.id === r.id,
              onClick: ($event: any) => (_ctx.toggleReader(r))
            }, null, 8, ["showInfo", "onClick"])
          ], 2),
          (_ctx.selectedReader && _ctx.selectedReader.id === r.id)
            ? (_openBlock(), _createBlock(_component_InfoTable, {
                key: 0,
                reader: _ctx.selectedReader
              }, null, 8, ["reader"]))
            : _createCommentVNode("", true),
          (_ctx.variant.reading_commentary)
            ? (_openBlock(), _createBlock(_component_SmallToggleButton, {
                key: 1,
                class: "bg-blue-light mb-2 inline-block ml-auto mr-1 border",
                onClick: _ctx.toggleCommentary,
                open: _ctx.showCommentary,
                text: _ctx.$t('variants.show_commentary')
              }, null, 8, ["onClick", "open", "text"]))
            : _createCommentVNode("", true),
          (_ctx.showCommentary)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "whitespace-normal text-left",
                innerHTML: _ctx.variant.reading_commentary
              }, null, 8, _hoisted_5))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ], 6),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Array.from({length: _ctx.verseCount}, (_, i) => i + 1), (index) => {
      return (_openBlock(), _createElementBlock("td", {
        class: _normalizeClass(["whitespace-nowrap p-1", [
        _ctx.selectedWord == index - 1 ? 'bg-blue-light' : '',
        _ctx.variant.work.canonical === 1 ? 'border-t border-black' : '',
      ]]),
        key: index
      }, _toDisplayString(_ctx.variant?.variants[index]), 3))
    }), 128))
  ], 2))
}