
import { defineComponent, PropType, ref } from 'vue';
import { VariantsReading } from '@/interfaces/VariantsReading';
import MoreInfo from '@/components/global/MoreInfo.vue';
import SmallToggleButton from '@/components/global/SmallToggleButton.vue';
import InfoTable from '@/components/variants/InfoTable.vue';
import { VariantsWork } from '@/interfaces/VariantsWork';
import { VariantsReader } from '@/interfaces/VariantsReader';
import { selectedWord } from '@/api/variants';

export default defineComponent({
  props: {
    variant: Object as PropType<VariantsReading>,
    odd: Boolean,
    verseCount: Number,
    freezeWidth: Number,
  },
  components: {
    MoreInfo,
    InfoTable,
    SmallToggleButton,
  },
  setup(props) {
    const selectedReader = ref<VariantsReader | undefined>(undefined);
    const selectedWork = ref<VariantsWork | undefined>(undefined);

    const showCommentary = ref<boolean>(false);
    function toggleCommentary() {
      showCommentary.value = !showCommentary.value;
    }

    function toDisplayName(s: VariantsWork, r: VariantsReader): string {
      if (s.canonical === 1 && +r.sigle % 100 === 0) {
        return `${+r.sigle / 100} ${r.display_name}`;
      }
      return r.display_name;
    }

    function toggleReader(r: VariantsReader) {
      if (selectedReader.value && selectedReader.value.id === r.id) {
        selectedReader.value = undefined;
      } else {
        selectedReader.value = r;
      }
    }
    function toggleWork() {
      if (selectedWork.value) {
        selectedWork.value = undefined;
      } else {
        selectedWork.value = props.variant?.work;
      }
    }

    function color(odd: boolean, source: VariantsWork): string {
      if (source.canonical === 1) {
        return 'bg-blue-dark text-white text-bold border-t border-black';
      }
      if (odd) {
        return 'bg-white';
      }
      return 'bg-grey-light';
    }

    return {
      color,
      showCommentary,
      toggleCommentary,
      selectedWord,
      selectedWork,
      selectedReader,
      toggleReader,
      toggleWork,
      toDisplayName,
    };
  },
});
